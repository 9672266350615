body {
    font-family: 'Roboto', sans-serif;
    color: #424242;
    margin: 0px !important;
}
a, a:hover {
  color: inherit;
}

.navbar {
  border-bottom: 5px solid #dbdbdb;
  background: white;
}
.navbar-brand {
  margin: 0px 10px;
}
.navbar.dash_layout {
  position:relative !important;
}

/* nav .nav-link {
  color: #17a2b8;
  font-weight: bold;
} */

.drawer_btn {
  color: #006643;
  cursor: pointer;
  margin-right: 10px;
}

/* .app_container {
  margin-top: 80px;
  margin-bottom: 100px!important;
} */
.app_container {
  margin-bottom: 30px;
  margin-top: 30px;
  min-height: 321px;
}

.container {
  max-width: 1920px !important;
}

.profile-dropdown > .nav-link {
  padding: 0px  !important;
}

.root_loader {
  text-align: center;
  margin-top: 100px;
}

.fredoka_ff {
  font-family: Fredoka One;
}

.not_verified {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    text-align: center;
    background: red;
    color: white;
}

/*============ Carrousel ==============*/

.carrousel_avatar {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 500px;
}

.carrousel_avatar div {
  position: absolute;
  width: 100%;
  height: 100%;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

}


/*/////////////  ARTICLE /////////*/
          

.article_container h1 {
  font-weight: 900;
  font-size: 50px;
  margin-top: 30px;
}
  
.article_container {
  font-size: 17px;
}

  .article_container .image{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 500px;
    background-position: top center !important;
  }


@media (max-width: 800px) {
  .article_container .image{
    height: 300px;
  }
  .article_container h1 {
    font-size: 35px;
  }
  
 
}

.nav-button-exit {
  margin: 20px;
}

.nav-profile {
  text-align: center;
}

.dropdown-toggle::after {
  display: none !important; 
}

.dropdown-profile-button {
  text-align: center;
  width: 100%;
}

.nav-link .dropdown-profile-button:hover {
  border-bottom-style: none!important;
}

/*/////////////  Scorecard /////////*/

.scorecard {
  width: 100%;
  background-color: #eeeeee;
  color: #363636;
  margin-top: 50px;
}

.scorecard .rating .MuiListItemText-secondary{
  font-size:50px;
}
.scorecard .MuiAvatar-colorDefault {
  background-color: #efefef;
}

.scorecard .chip {
  margin: 5px 10px 5px 0px;
}
.scorecard .MuiSvgIcon-root {
color: #525252;
}

/*/////////////  auth /////////*/

.auth_container { 
  width: 250px;
  margin: 0 auto;
}

.auth_container h1 {
  text-align: center;
}
.auth_container button {
  width: 100%;
}

@media (max-width: 800px) {
  .auth_container {
    width: 100%;
  } 
}

/*////////// dashboard /////////*/

.adminLayout {
  height:100vh;
}

.app_container.dash_layout {
  margin-top: 0px;
  margin-bottom: 0px !important;
  max-width: 100%;
}

.dash_layout nav{ 
  background: #212121;
  padding: 0px;
  border-right: 5px solid #565656;
}

.dash_layout .MuiList-root {
  color: #fff;
}

.dash_layout .MuiButtonBase-root {
  border-bottom: 1px solid #313131;
}

/*///// forms /////////////*/

.chip_container {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.chip_container .MuiChip-root {
  margin-left: 10px;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}
.rdw-editor-main {
  border: 1px solid #F1F1F1;
  padding: 10px;
}

.actors_form {
  border: 1px solid #c9c9c9;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 400px;
  margin-bottom: 10px;
}

.actors_form .input {
  margin-left: 10px;
  flex: 1;
}

.actors_form .MuiButtonBase-root {
  border-bottom:0px
}
.article_form {
  padding-bottom: 50px;
}
.article_form .MuiSelect-root {
  min-width: 200px;
}

.stepper_form {
  text-align: center;
}

.align-right {
  text-align: right;
}

.error-content {
  text-align: center;
  margin-top: 71px;
}

.auth_grid input {
  font-size: 20px;
}

.form-group {
margin-top: 20px;
}

.rdw-editor-toolbar,
.rdw-editor-main {
  border-color: rgba(0, 0, 0, 0.23);
}



/*///// DASHBOARD TABLE /////////////*/

.articles_table .action_btn{
  width: 100px;
  text-align: center;
  font-weight: 900;
  color: #fff;
  cursor: pointer;
}

.articles_table .remove_btn {
  background: #dc3545;
  color: white !important;
}
.articles_table .remove_btn:hover {
  background: #ee7782;
  color: white !important;
}

.articles_table .edit_btn{ 
  background: #117a8b;
  color: white !important;
}
.articles_table .edit_btn:hover{ 
  background: #3d99a8;
  color: white !important;
}

.articles_table .status_btn {
  background: #ffc107;
  color: white !important;
}
.articles_table .status_btn:hover{ 
  background: #ffd24c;
  color: white !important;
}

/* .articles_table .table-striped>tbody>tr:nth-of-type(odd)>* {
  color: white;
} */


.background-grey {
  background-color: #EDEDED;
}


/* 
.top-nav {
  background-color: #EDEDED;
  color: #006643;
 
  min-height: 50px;

  text-align: center;
  padding: 10px;
} */

.bottom-nav {
  background-color: #EDEDED;
  color: #006643;
  text-align: center;
  padding: 10px;
}


.footer-up-icon {
  text-align: right;
}


.footer-up-icon > button {
  margin: 0px;
  padding: 0px;
}



.carousel-element {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%
}
/* .footer-nav {
  background-color: #006643;
  color:white;
  position: fixed;
  height: 42px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  text-align: center;
  padding: 5px;
} */

.footer-nav {
  background-color: #006643;
  color: #fff;
  margin-bottom: 0;
  padding: 36px;
}

.top-navbar {
  min-height: 45px;
  padding: 10px 10px;
}

.social-icons a {
  margin-left: 20px;
  color: #006643;
}


@media (max-width: 992px) {
  .navbar-nav {
    height: 100vh !important;
  }
  .nav-link {
    border-bottom-style: groove;
    border-bottom: #c9c9c9;
  }

  .social-icons {
    bottom: 15px;
    position: fixed;
    align-content: center;
    margin: 0 -17px;
    width: 100%;
    text-align: center;
  }
}


.logo-big {
  margin-right: 50px;
}

.header-logo {
  background-image: url(../../public/logo-cisl.png);
}


.green-text {
  color: #006643;
}

.green-rectangle {
  background-color: #006643;
  margin-right: 10px;
}



.grey-rectangle {
  background-color: #525252;
  margin-right: 10px;
}

.green-rectangle-input {
  background-color: #006643;
  margin-right: 10px;
  font-size: 34px;
  display: inline;
}

.grey-card {
  background-color: #EDEDED;
  margin: 6px 0;
  padding: 8px;
  overflow: hidden;
}

.operation-card {
  background-color: #EDEDED;
  margin: 6px;
  padding: 8px;
  cursor: pointer;
  min-width: 170px;
}

.account-avatar {
  border-radius: 50%;
}


.rounded {
  border-radius: 50%!important;
}

.user-card-label {
  font-size: 20px;
}
.padding-20 {
  padding: 20px;
}

.layout-card {
  margin: 10px;
}

.layout-card-title {
  font-size: 20px;
}



.element-card {
  
  width: 100%;
  border-radius: 5px;
  margin:5px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  height: 140px;
  text-overflow: ellipsis;
}


.folder-card {
  
  width: 100%;
  border-radius: 5px;
  margin:5px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  height: 102px;
  text-overflow: ellipsis;
  text-align: center;
}

.box-title {
  margin: 0 5px;
}

.highlight-element {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.highlight-element:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.element-card-content {
  min-height: 80px;
  text-align: center;
  color: #006643;
}


.box-content {
  margin-top: 5px;
  /*min-height: 80px;
  text-align: center;*/
  color: #006643;
}

.green-content {
  color: #006643;

}
/* .rss-feed-image {
  max-width: 80px;
  border-radius: 5px;
  margin: 5px;
} */

.rss-feed-container {
 position: relative;
 text-align: center;

}
.rss-feed-title {
  color: #ffffff;
  position: absolute;
  bottom: 27px;
  left: 16px;
  font-size: 20px;
  /*background:rgba(235, 235, 235,0.3);give opacity to bg color only */
  background:rgba(0, 0, 0,0.6);/* give opacity to bg color only */
  overflow: hidden;
  max-height: 80%;

}



.rss-feed-image {
  width: 100%;
  border-radius: 5px;
  margin: 5px;
  
  height: 350px;
  object-fit: cover;
}



.header-marquee-text {
  margin: 0 400px;
}



.grey-content {
  color: #525252;
}

.folder-row-operations-col {
  text-align: right;
}

.folder-row {
  width: 100%;
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-decoration: none;

}


.folder-row-content {
  text-align: center;
  color: #006643;
}


.folder-container {
  padding: 2px 16px;
  margin-top: 20px;
}

.folder-big-card {
  min-height: 120px;
  margin-top: 30px;
}


.card-input {
  width: 100%;
  padding: 2px 10px;
}

.button-right {
  text-align: right;
}

.folder-operations {
  text-align: right;
}

.ui-button {
  background-color: #C60C30!important;

  color: white!important;
  border-color: #C60C30!important;
  margin: 10px 0!important;
  border-radius: 5px!important;
}
.green-button {
  background-color: #006643!important;

  color: white!important;
  border-color: #006643!important;
  margin: 10px 0!important;
  border-radius: 5px!important;
}

.grey-button {
  background-color: #525252!important;

  color: white!important;
  border-color: #525252!important;
  margin: 10px 0!important;
  border-radius: 5px!important;
}





.btn-block {
  width: 100%;
}

.centered-text {
  text-align: center!important;
}

.red-text {
  color:#C60C30!important;

}

.nav-link {
  
  color: #424242!important;
  font-weight: bold;

}


.nav-link:focus, .nav-link:hover {
  color: #006643!important;
  border-bottom: #006643;
  /* border-bottom-style: groove; */

}




.ui-icon {
  color: #006643!important;
  cursor: pointer;
  text-align: center;

}

.file-uploader {
  cursor: pointer;
  padding: 18px;
}

.titlebar {
  width: '100%';
  background-color:#EDEDED;
  margin: 4px;
}

.post-textarea {
  border: none;
  resize: none;
  outline: none;
  width: 100%;
  /* min-height: 400px; */
  display: block;
  overflow: auto;
  margin-top: 20px;
}

.post-textarea > P {
  margin: 0px;
  padding: 0px;
}

.comment-div {
  max-height: 200px;
  overflow-y: auto;
}

.comment-div > P {
  margin: 0px;
  padding: 0px;
}


.post-title {
  font-size: 34px;
  border: none;
  background-color: transparent;
  margin: 5px;
  width: 95%;
  display: inline;
}

.grey {
  background-color: #EDEDED!important;

  color: black!important;
  border-color: #EDEDED!important;
}

.post-title:focus {
  outline: none;
}

.folder-row-title {
  overflow: hidden;
}

.post-title-container {
  white-space: nowrap;
  
}

.app-icon {

  background-color: #006643;
    border-radius: 5px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    min-width: 107px;
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;

}

.app-icon-content {
  color: white;
}

.app-icon > a {
  text-decoration: none;
}

.app-icon-image {
  max-width: 40px;
  max-height: 40px;
}

.css-if10g5 {
  font-size: 40px;
}

.email-from {
  padding: 0 5px;
}
/*
.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown-content:hover .dropdown-content {
  display: block;
}
*/

.dropdown-content a {
  color: black;
  padding: 8px;
  text-decoration: none;
  opacity: 100%;
}

.bold-hover:hover {
  font-weight: bold;
}

.deleted-element {
  opacity: 40%;
}




.deleted-row {
  background-color: #dbdbdb;
}


.dropdown-content a:hover {
  background-color: rgba(80, 80, 80, 0.3);
  cursor: pointer;
  z-index: 10;
}


.dropdown-content {
  display: none;
  position: relative;
  bottom: 8px;
  right: 0;
  width: 100%;
  transition: 2s;

}

 .dropdown-container:hover  .dropdown-content{
  display: block;
  text-align: center;

}


.menu-link {
  text-align: center;
}


.comment-avatar {
  margin: 10px;
}
.comment-box {
  margin: 10px;
  background: #EDEDED;
  padding: 15px;
  position:relative;
  border-radius: 2px;

}

.comment-box:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 10px solid #EDEDED;
  border-left: 10px solid transparent;
  border-top: 10px solid #EDEDED;
  border-bottom: 10px solid transparent;
  left: -20px;
  top: 10px;
}



.comment-title {
  color: #006643;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
}



.comment-body {
  font-size: 16px;
  margin-bottom: 14px;
}

.comment-date {
  font-size: 12px;
  text-align: right;
}

.child-comments {
  padding-left: 50px;
}

.comment-textarea {
  background-color: transparent;
    border: 0;
    resize: none;
    width: 100%;
    height: 100%;
    outline: none;
}

.comment-textarea:disabled {
  background-color: #EDEDED
}

.grey-preview {
  background-color: #EDEDED
}

.white-preview {
  background-color: white
}

.new-comment-box {
  height: 50px;
  padding: 0 15px;
}


.comment-button {
  float: right;
  cursor: pointer;
}

.no-padding {
  padding: 0!important;
}

.search-input {
  width: 100%;
}

.no-padding > .file-uploader {
  padding: 0!important;
}

.modal-history-body {
  max-height: 500px;
  overflow-y: scroll;
}




#tinymce > p {
  margin: 0px;
  padding: 0px;

}

.right-link {
  float: right;
  cursor: pointer;
}

.breadcrumb-element {
  padding: 0 3px;
  overflow: hidden;
  white-space: nowrap;
}

.post-preview {
  padding: 5px;
}

.post-preview-content {
  font-size: 16px;
}

.post-preview-content:hover {
  font-size: 16px;
  text-decoration: underline;
}

.post-preview-content > a{
  cursor: pointer;
}

.post-preview > a {
  text-decoration: none;
  color: #424242;
  font-weight: normal;
}

.email-preview {
  padding: 5px;
}

.email-preview-content {
  font-size: 13px;
}

.email-preview-content > a{
  cursor: pointer;
}

.email-preview > a {
  text-decoration: none;
  color: #424242;
  font-weight: normal;
}

.user-card-content {
  text-align: center;
}

.elements-grid-bar {
  margin-left: 2px;
  background-color: #EDEDED;
  min-height: 30px;
  cursor: pointer;
  text-align: center;
}

.grid-bar-icon {
  margin: 5px;
}

.input-subfolder {
  width: 100%;
}


.input-editfolder {
  width: 100%;
  resize: none;
  outline: none;
  border: none;
}

.html-editor-container {
  margin-top: 40px;
  margin-bottom: 60px;
}

.even {
  background-color: white;

}

.odd {
  background-color: #dbdbdb
}

.filter-input {
  height: 38px;
  background: transparent;
  border: none;
  background: url("/public/search-solid.png") no-repeat scroll 12px 12px;
  background-size: 14px;
  width: 100%;
  padding-left:36px;
  
}

.element-card-name-box {
  padding-top: 14px;
}
.top-search-icon {
  padding-top: 6px;
}


.red-dot {
  height: 10px;
  width: 10px;
  background-color: #C60C30;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: #006643;
  border-radius: 50%;
  display: inline-block;
}

.hidden-dot {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
}


.admin-options {
  text-align: center;
}

.modal-move-body {
  overflow-y: scroll;
  height: 400px;
}


.elements-grid-footer {
  text-align: center;
}

.main-logo {
  text-align: left;
}




.operation-dropdown {
  /* top: 7px; */
  text-align: right;
  /* margin: 10px 0; */
  display: inline-block;
}

.dropdown-button {
    background-color: transparent;
    color: #006643;
    border: 0;
}

.btn-primary {
  color: #006643;
  background-color: transparent;
  border-color: #006643;

}
.btn-primary:focus {
  border-color: #006643;
  
}


.dropdown-button:focus {
  background-color: transparent;
  color: #006643;
  border: 0;
  border-color: #006643;
}

.dropdown-button:hover {
  background-color: #006643;
  color: white!important;
  border: 0;
}

.modal-dialog {
  max-width: 950px;
}

/* .email-preview-grid {
  text-align: center;
} */

input {
  border: none;
  background: transparent;
}
input:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: #006643;
  font-weight: bold;
}


.notice-box > * {
  font-size: 16px!important;
}


a:hover {
  color: #C60C30;
}

select {
  background: transparent;
  outline: none;
  border-radius: 3px;
  height: 32px;
  width: 100%;
}


select option {
  background: transparent;
}

.hidden {
  display: none;
}


.version-info {
  text-align: right;
}



.email-table {
  overflow-x: scroll;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  outline: none;
}

.dropzone-text {
  font-size: 16px;
  color: #333;
}

.file-item {
  background-color: #f7f7f7;
  padding: 12px;
  border-radius: 4px;
}

.file-title {
  margin-bottom: 10px;
  font-size: 14px; /* Dimensione originale del titolo del file */
}

.file-name {
  flex-grow: 1;
  font-size: 14px;
  color: #333;
}

.upload-progress {
  width: 100%; /* Barra di caricamento estesa */
  margin-top: 8px;
}

.delete-button,
.cancel-upload-button {
  margin-left: 10px;
}


/* .dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: border 0.3s ease-in-out;
}

.dropzone:hover {
  border: 2px dashed #aaa;
}

.file-list {
  margin-top: 20px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 4px;
}

.file-item span {
  flex-grow: 1;
  margin-right: 10px;
}

.multi-uploader {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.multi-uploader:hover {
  background-color: #45a049;
} */


.action-button
{
  width: 100%;
  cursor: pointer;
  /*height: 25px;*/
  padding: 0;
  font-size:  14px;
}


.breadcrumb-container {
  display: flex; 
  flex-direction: row; 
  flex-wrap: wrap; 
  text-align: left; 
}

.breadcrumb-container-post {
  margin-bottom: 15px;
}

/* 
 .breadcrumb-container :nth-child(1) { order: 1; }
.breadcrumb-container :nth-child(2) { order: 2; }
.breadcrumb-container :nth-child(3) { order: 3; }
.breadcrumb-container :nth-child(4) { order: 4; }
.breadcrumb-container :nth-child(5) { order: 5; }  */